const  toHexString  = (str) => {
    console.log(str);
    if(!str)return "";
    var hexes = [];
    for(var i = 0; i < str.length; i++) {
        hexes.push("0x");
        hexes.push((str.charCodeAt(i)).toString(16));
    }
    return hexes.join("");
}
export default{
    encode: (condition) => toHexString(condition)
}