<template>
  <div>
    <el-row>
      <el-col :span="6">
        <img
          src="/image/web/logo.png"
          style="margin-top: 2px; cursor: pointer"
          @click="goWeb"
        />
      </el-col>
      <el-col
        :span="18"
        style="text-align: right; height: 50px; line-height: 50px"
      >
        <el-popover placement="bottom" trigger="hover">
          <el-menu style="border-right:none;">
            <el-menu-item>
              <el-button
                size="medium"
                type="text"
                icon="el-icon-edit"
                @click="showResetPwd"
                >修改密码</el-button
              >
            </el-menu-item>
            <el-menu-item>
              <el-button
                size="medium"
                type="text"
                icon="el-icon-switch-button"
                @click="logout"
                >退出</el-button
              >
            </el-menu-item>
          </el-menu>
          <i
            style="font-size: 18px"
            slot="reference"
            class="el-icon-user-solid"
          ></i>
        </el-popover>
      </el-col>
    </el-row>

    <el-dialog
      title="修改密码"
      :visible.sync="resetPasswordDialogVisible"
      width="22%"
      :modal-append-to-body='false'
    >
      <el-form
        ref="resetPasswordForm"
        :model="passwordInfo"
        :rules="rules"
        label-width="80px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="原始密码">
              <el-input
                v-model="passwordInfo.opassword"
                type="password"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-col :span="24">
          <el-form-item label="新密码">
            <el-input
              v-model="passwordInfo.npassword"
              type="password"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="确认密码" prop="npassword2">
            <el-input
              v-model="passwordInfo.npassword2"
              type="password"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetPasswordDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="resetPwd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import Session from "@/utils/session";
import UserService from "@/api/sys/user";
export default {
  name: "Header",
  data() {
    return {
      resetPasswordDialogVisible: false,
      passwordInfo: {
        opassword: "",
        npassword: "",
        npassword2: "",
      },
      rules: {
        npassword2: [
          {
            validator: (rule, value, callback) => {
              if (value != this.passwordInfo.npassword) {
                callback("两次密码不一致");
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  methods: {
    logout() {
      Session.clearUser();
      this.$router.push({ path: "/sys/login" });
    },
    goWeb() {
      this.$router.push({ path: "/web/home" });
    },
    showResetPwd(){
      this.resetPasswordDialogVisible = true;
    },
    resetPwd() {
      this.$refs.resetPasswordForm.validate((valid) => {
        if (valid) {
          UserService.resetPwd(this.passwordInfo.opassword,this.passwordInfo.npassword).then(
            (r) => {
              this.$message.success("修改成功");
              this.resetPasswordDialogVisible = false;
            },
            (err) => {}
          );
        }
      });
    },
  },
};
</script>
<style scoped>
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu--horizontal > .el-menu-item a {
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  padding: 8px 20px;
  line-height: 20px;
}
.el-menu--horizontal > .el-menu-item:hover {
  border-bottom: 2px solid #409eff;
}
</style>