<template>
    <el-pagination
      background
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page.sync="pager.pageNumber"
      :page-sizes="[5, 10, 15, 20,100]"
      :page-size="pager.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.total"
      style="
    background-color: #fff;
    padding-top: 40px;
    padding: 20px;
    text-align: right;
    "
      >
    </el-pagination>
</template>
<script>
export default {
  name: "Pager",
  props: {
    pager: Object
  },
  data() {
    return {
    };
  },
  methods: {
    sizeChange(pageSize){
      let pageNumber = this.pager.pageNumber;
      this.$emit("change",pageNumber,pageSize);
    },
    currentChange: function (pageNumber) {
      let pageSize = this.pager.pageSize;
      this.$emit("change",pageNumber,pageSize);
    },
  },
};
</script>