import Vue from 'vue';
import Vuex from 'vuex';
import Element from 'element-ui';
/**import 'element-ui/lib/theme-chalk/index.css';*/
import '../theme/index.css';
import '@/assets/css/global.less'
import VueRouter from 'vue-router';
import App from './App.vue';
import layoutMap  from './layouts/layout';

Vue.config.productionTip = false;
Vue.use(Element, { size: 'small', zIndex: 3000 });
/**
 * 注册布局
 */
console.log("layouts:");
const layoutContext = require.context("./layouts", true, /\.vue$/);
layoutContext.keys().reduce((preKey,key)=>{
    let path = key.replace("./","/").replace(".vue","");
    path = path.startsWith("/")?path.substring(1):path;
    let name = path.replace("/","-").toLowerCase();
    if(name.endsWith("-")){
      name = name.substring(0,name.length-1);
    }
    let component = resolve => require(['./layouts/'+path+".vue"], resolve);
    Vue.component(name+"-layout",component);
    console.log("->"+name+"-layout");
    return key;
},null);

/**
 * 注册状态仓库
 */
console.log("stores:");
Vue.use(Vuex);
import {rootStore} from '@/stores/store';
const store =  new Vuex.Store(rootStore);
const storeContext = require.context("./stores", true, /\.js$/);
const stores = storeContext.keys().reduce((stores,key)=>{
    let path = key.replace("./","/").replace(".js","");
    path = path.startsWith("/")?path.substring(1):path;
    if(path!='store') stores[path] = storeContext(key).default;
    return stores;
},{});
for(let path in stores){
  stores[path].namespaced = true;
  store.registerModule(path, stores[path]);
  console.log("->"+path)
}
console.log("store:");
console.log(store);
/**
 * 注册路由
 */
console.log("routes:")
Vue.use(VueRouter);
const routeContext = require.context("./views", true, /\.vue$/);
const routes = routeContext.keys().reduce((routes,key)=>{
    let path = key.replace("./","/").replace(".vue","");
    let name = path.split("").reduce((name,char)=>name.endsWith("/")?  name.replace("/","")+char.toUpperCase():name+char,"");
    let component = resolve => require(['./views'+path+".vue"], resolve);
    Vue.component(name,component);
    
    let layout = 'default-layout';
    for(let p in layoutMap){
      if(path.startsWith(p)){
        layout = layoutMap[p];
        break;
      }
    }
    
    routes.push({path,name,component,meta:{layout}});
    console.log("->"+name+":"+path+"["+layout+"]");
    return routes;
},[]);
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
});
/**
 * 路由拦截
 */
import Session from '@/utils/session';
router.beforeEach((to, from, next)=> {
  if(to.meta.title){
    document.title = to.meta.title;
  }else{
    document.title = SYS_NAME;
  }
  let user = Session.getUser();
  if(to.path=="" || to.path=="/" || to.path.startsWith("/web")){
    if(to.path=="" || to.path=="/"){
      next({path: '/web/home'})
    }else{
      next();
    }
  }else if (!user && to.path!='/sys/login'){
    next({path: '/sys/login'});
  }else{
    next();
  }
});

let vm  = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
