<template>
  <el-aside style="width: auto">
    <el-menu
      default-active=""
      class="el-menu-vertical"
      @open="open"
      @close="close"
      :collapse="isCollapse"
      :router="true"
    >
      <template v-for="menu in menus">
        <el-menu-item
          v-if="menu.nodes == null || menu.nodes.length == 0"
          :key="menu.id"
          :index="menu.url"
        >
          <i :class="menu.icon"></i>&nbsp;
          <span slot="title">{{ menu.name }}</span>
        </el-menu-item>
        <el-submenu v-else :key="menu.id" :index="menu.id">
          <template slot="title">
            <i :class="menu.icon"></i>&nbsp;
            <span slot="title">{{ menu.name }}</span>
          </template>
          <el-menu-item
            v-for="sub in menu.nodes"
            :key="sub.id"
            :index="sub.url"
          >
            <i :class="sub.icon"></i>&nbsp;
            <span slot="title">{{ sub.name }}</span>
          </el-menu-item>
        </el-submenu>
      </template>

      <div class="expand-icon">
        <i :class="expandClass" @click="expand"></i>
      </div>
    </el-menu>
  </el-aside>
</template>

<style>
.el-aside {
  background-color: #fff;
  color: #333;
  text-align: left;
  height: calc(100vh - 60px);
}
.el-menu-vertical:not(.el-menu--collapse) {
  width: 232px;
  height: 100%;
}
.el-menu {
  height: 100%;
}
.expand-icon {
  position: absolute;
  bottom: 0;
  line-height: 38px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  text-align: left;
  padding-left: 24px;
}
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 120px !important;
}
</style>

<script>
import Session from "@/utils/session";
import ArrayUtil from "@/utils/array-util";
export default {
  name: "Menu",
  data() {
    return {
      isCollapse: false,
    };
  },
  computed: {
    expandClass() {
      return this.isCollapse ? "el-icon-s-unfold" : "el-icon-s-fold";
    },
    menus() {
      let user = Session.getUser();
      return ArrayUtil.toTree(user && user.menus, { pId: "pid" }) || [];
    },
  },
  methods: {
    expand() {
      this.isCollapse = !this.isCollapse;
    },
    open(key, keyPath) {
      console.log(key, keyPath);
    },
    close(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>