<template>
    <section>
        <div class="banner">
            <img src="/image/web/banner.jpg"/>
        </div>
        <div class="service">
            <div class="service-item">
                <img src="/image/web/service-app.jpg"/>
                <div class="name">APP定制开发</div>
            </div>
           <div class="service-item">
                <img src="/image/web/service-web.jpg"/>
                <div class="name">网站定制开发</div>
            </div>
            <div class="service-item">
                <img src="/image/web/service-sys.jpg"/>
                <div class="name">软件系统开发</div>
            </div>
            <div class="service-item">
                <img src="/image/web/service-wxapp.jpg"/>
                <div class="name">微信定制开发</div>
            </div>
        </div>
        <el-row class="copyright">
            <el-col><span>技术支持：云之贡科技</span><span style="margin-left:15px;">邮箱：service@yunzhg.com</span><br/><br/>
            <span><el-link href="https://beian.miit.gov.cn/">备案号：滇ICP备2022000882号</el-link></span>
            </el-col>
      </el-row>
    </section>
</template>
<script>
export default {
}
</script>
<style scoped>
.banner{
    height: 792px;
    margin-top:124px;
}
.service{
    border: dashed 1px gray;
    background-color: rgb(47, 205, 144);
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.service .service-item{
    width: 300px;
    height: 192px;
    border-left:solid white 1px;
    display: flex;
    justify-content:  center;
    flex-direction: column;
    align-items: center;
}
.service .service-item:last-child{
    border-right:solid white 1px;
}
.service .service-item IMG{
    width: auto;
    display: block;
}
.service .service-item .name{
    font-size: 18px; 
    font-family: 微软雅黑; 
    color: rgb(255, 255, 255);
    margin-top: 24px;
}
.copyright{
  text-align: center;
  width: 100%;
  height: 100px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 14px;
  color: #909399;
  opacity: 1;
  margin-top:30px;
}
</style>