import $http from "@/utils/http";
import qs from 'qs'

export default{
    pager: params => $http.get("/rest/sys/post/pager", {params,loading: true}),
    list: params => $http.get("/rest/sys/post", {params,loading: false}),
    create: role => $http.post("/rest/sys/post", role, {loading: true}),
    update: role => $http.put("/rest/sys/post", role, {loading: true}),
    remove: id => $http.delete(`/rest/sys/post/${id}`, {loading: true}),
    exists: params => $http.get("/rest/sys/post/exists", {params,loading: false}),

    listPostUser: postId => $http.get(`/rest/sys/post/${postId}/user`, {loading: true}),
    addPostUser: (postId,userId) => $http.post(`/rest/sys/post/${postId}/${userId}`, {}, {loading: true}),
    removePostUser: (postId,userId) => $http.delete(`/rest/sys/post/${postId}/${userId}`, {loading: true}),
}