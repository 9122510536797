<template>
    <div>
        <el-row>
            <el-col :span="8" style="text-align: left;">
                <el-button type="primary" @click="showAddDialog" class="el-icon-plus">岗位</el-button>
            </el-col>
            <el-col :span="16" style="text-align: right;">
                <el-form :inline="true" :model="params" @submit.native.prevent>
                    <el-form-item>
                        <el-input v-model="params.keyword" placeholder="名称/编码/描述" @keyup.enter.native="search(1)"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search(1)">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="6">
                <el-table ref="orgTree" :data="orgTree" row-key="id" :fit="true" border default-expand-all
                 highlight-current-row @current-change="currentOrgChange">
                    <el-table-column prop="name" label="机构名称"></el-table-column>
                </el-table>
            </el-col>
            <el-col :span="18" style="padding-left:20px;">
                <el-table :data="pager.rows">
                    <el-table-column prop="name" label="名称"></el-table-column>
                    <el-table-column prop="code" label="编码"></el-table-column>
                    <el-table-column prop="description" label="描述"></el-table-column>
                    <el-table-column prop="id" label="操作" width="300" align="center">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" size="mini" type="text" @click="showUpdateDialog(scope.row)">修改</el-button>
                            <el-button icon="iconfont icon-gangwei" size="mini" type="text" @click="showUserConfigDialog(scope.row)">配置人员</el-button>
                            <ConfirmButton :content="'确定删除['+scope.row.name+']吗？'" @ok="remove(scope.row.id)"/>
                        </template>
                    </el-table-column>
                </el-table>
                <Pager :pager="pager" @change="search"/>
            </el-col>
        </el-row>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
          <el-form ref="postForm" :model="post" :rules="rules" label-width="80px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="名称" prop="name">
                  <el-input v-model="post.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属机构" prop="orgName">
                  <el-input v-model="post.orgName" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                  <el-form-item label="编码" prop="code">
                    <el-input v-if="post.id" v-model="post.code" disabled></el-input>
                    <el-input v-else v-model="post.code"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="排序" prop="orderNum">
                    <el-input v-model="post.orderNum" type="number"></el-input>
                  </el-form-item>
                </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="描述" prop="description">
                  <el-input v-model="post.description" type="textarea" :rows="3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="createOrUpdate">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog title="配置人员" :visible.sync="userConfigDialogVisible" width="40%">
            <el-row>
              <el-col style="text-align: right;">
                <el-form :inline="true" :model="params" @submit.native.prevent>
                    <el-form-item>
                        <el-select
                          v-model="postUser.userId"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="账号/姓名/电话"
                          :remote-method="listOrgUser"
                          :loading="selectOrgUsersLoading">
                          <el-option
                            v-for="user in orgUsers"
                            :key="user.id"
                            :label="user.realname"
                            :value="user.id">
                          </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                         <el-button type="primary" class="el-icon-plus" @click="addPostUser()">添加</el-button>
                    </el-form-item>
                </el-form>
              </el-col>
            </el-row>
            <el-table
              :data="postUsers"
              style="width: 100%">
              <el-table-column prop="username" label="账号"></el-table-column>
              <el-table-column prop="realname" label="姓名"></el-table-column>
              <el-table-column prop="phone" label="电话"></el-table-column>
              <el-table-column prop="id" label="操作" width="300" align="center">
                <template slot-scope="scope">
                  <ConfirmButton :content="'确定删除['+scope.row.realname+']吗？'" @ok="removePostUser(scope.row.id)"/>
                </template>
              </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button @click="userConfigDialogVisible = false">关闭</el-button>
          </span>
        </el-dialog>

    </div>
</template>

<script>

import PostService from "@/api/sys/post";
import OrgService from "@/api/sys/org";
import UserService from "@/api/sys/user";
import ConfirmButton from '@/components/common/ConfirmButton';
import Pager from '@/components/common/Pager';
import Hex from '@/utils/hex';
import Validator from '@/utils/validator';
import ArrayUtil from "@/utils/array-util";

export default {
    components:{ConfirmButton,Pager},
    data(){
        return{
            orgTree:[],
            org:{},
            pager:{
                rows:[],
                total:0,
                pageSize: 10,
                pageNumber: 1
            },
            params:{
                keyword: ''
            },

            userConfigDialogVisible: false,
            selectOrgUsersLoading: false,
            orgUsers:[],
            postUsers:[],
            postUser:{},

            dialogTitle:'',
            dialogVisible: false,
            post:{},
            rules:{
              name:[
                {
                  required: true,
                  message: '名称不能为空',
                  trigger: 'blur',
                },{
                  validator: (rule, value, callback) => { 
                    PostService.exists({condition: Hex.encode(`id!=${this.post.id} && orgId=${this.org.id} && name=${value}`)})
                    .then((exists)=>{
                      if(exists){
                        callback("名称重复");
                      }else{
                        callback();
                      }
                    })
                  },
                  trigger: 'blur'
                }
              ],
              code:[
                {
                  required: true,
                  message: '编码不能为空',
                  trigger: 'blur',
                },{
                  validator: (rule, value, callback) => { 
                    PostService.exists({condition: Hex.encode(`id!=${this.post.id} && orgId=${this.org.id}  && code=${value}`)})
                    .then((exists)=>{
                      if(exists){
                        callback("编码重复");
                      }else{
                        callback();
                      }
                    })
                  },
                  trigger: 'blur'
                }
              ],
            }
        }
    },
    created: function () {
        this.loadOrgTree();
    },
    methods:{
        currentOrgChange(org){
            this.org = org;
            this.search(1);
        },
        showAddDialog(){
          this.dialogTitle = "新增岗位";
          this.dialogVisible = true;
          this.$refs.postForm && this.$refs.postForm.resetFields();
          this.post = {
              orgId: this.org.id,
              orgName: this.org.name,
              id: null,
              name: '',
              code: '',
              orderNum: 0,
              description: ''
          };
        },
        showUpdateDialog(post){
          this.dialogTitle = "修改岗位";
          this.dialogVisible = true;
          this.$refs.postForm && this.$refs.postForm.resetFields();
          this.post = {...post};
        },
        showUserConfigDialog(post){
          this.post = {...post};
          this.userConfigDialogVisible = true;
          this.listPostUser();
        },
        createOrUpdate(){
          this.$refs.postForm.validate((valid) =>{
            if(valid){
              if (!this.post.id) {
                PostService.create(this.post).then(
                  (r) => {
                    this.$message.success("新增成功");
                    this.dialogVisible = false;
                    this.search();
                  },
                  (err) => {}
                );
              } else {
                PostService.update(this.post).then(
                  (r) => {
                    this.$message.success("修改成功");
                    this.dialogVisible = false;
                    this.search();
                  },
                  (err) => {}
                );
              }
            }
          });
        },
        remove(id){
          PostService.remove(id).then(
              (r) => {
                this.$message.success("删除成功");
                this.search();
              },
              (err) => {}
          );
        },
        listOrgUser(keyword){
          this.selectOrgUsersLoading = true;
          UserService.pager({
            condition: Hex.encode(`orgId=${this.org.id} && (username=%${keyword}% || realname=%${keyword}%) || phone=%${keyword}%)`)
          }).then(pager=>{
            this.selectOrgUsersLoading = false;
            this.orgUsers = pager.rows;
          },err=>{
            this.selectOrgUsersLoading = false;
          });
        },
        listPostUser(keyword){
          PostService.listPostUser(this.post.id).then(postUsers=>{
            this.postUsers = postUsers;
          });
        },
        addPostUser(){
          PostService.addPostUser(this.post.id,this.postUser.userId).then(r=>{
            this.postUser={};
            this.listPostUser();
          });      
        },
        removePostUser(id){
          PostService.removePostUser(this.post.id,id).then(
              (r) => {
                this.listPostUser();
              },
              (err) => {}
          );
        },
        search(pageNumber,pageSize) {
            if(!pageNumber) pageNumber = this.pager.pageNumber;
            if(!pageSize) pageSize = this.pager.pageSize;
            PostService.pager({
                pageSize: pageSize,
                pageNumber: pageNumber,
                condition: Hex.encode(`orgId=${this.org.id} && (name=%${this.params.keyword}% || code=%${this.params.keyword}% || description=%${this.params.keyword}%)`)
            }).then((pager) => {
                this.pager = pager;
            });
        },
        loadOrgTree() {
            OrgService.list({order: 'orderNum asc'}).then((orgs) => {
                const orgTree =
                ArrayUtil.toTree(
                    orgs,
                    { pId: "pid", nodes: "children" },
                    null,
                    "children"
                ) || [];
                this.orgTree = orgTree;
                if(this.orgTree && this.orgTree.length){
                    this.$refs.orgTree.setCurrentRow(this.orgTree[0]);
                }
            });
        },
    }
}
</script>
<style scoped>
</style>