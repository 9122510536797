<template>
  <div>
    <el-row>
      <el-col :span="24" style="text-align: right">
        <el-form :inline="true" :model="params" @submit.native.prevent>
          <el-form-item>
            <el-select v-model="params.type" placeholder="类型">
              <el-option label="全部" value=""></el-option>
              <el-option label="操作日志" value="operator"></el-option>
              <el-option label="系统日志" value="sys"></el-option>
              <el-option label="认证日志" value="auth"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="params.daterange"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="params.keyword"
              placeholder="名称/用户/IP"
              @keyup.enter.native="search(1)"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search(1)">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <el-table :data="pager.rows">
      <el-table-column prop="type" label="类型"></el-table-column>
      <el-table-column prop="name" label="操作"></el-table-column>
      <el-table-column prop="ip" label="IP地址"></el-table-column>
      <el-table-column prop="userName" label="用户"></el-table-column>
      <el-table-column prop="time" label="时间"></el-table-column>
    </el-table>
    <Pager :pager="pager" @change="search" />
  </div>
</template>

<script>
import LogService from "@/api/sys/log";
import Pager from "@/components/common/Pager";
import Hex from "@/utils/hex";

export default {
  components: { Pager },
  data() {
    return {
      pager: {
        rows: [],
        total: 0,
        pageSize: 10,
        pageNumber: 1,
      },
      params: {
        daterange: "",
        type: "",
        keyword: "",
      },
    };
  },
  created() {
    this.search(1);
  },
  methods: {
    search(pageNumber, pageSize) {
      if (!pageNumber) pageNumber = this.pager.pageNumber;
      if (!pageSize) pageSize = this.pager.pageSize;

      const k = `name=%${this.params.keyword}% || ip=%${this.params.keyword}% || userName=%${this.params.keyword}%`;
      let c = "1=1 ";
      c += this.params.type === "" ? "" : `&& type=${this.params.type} `;
      c += (!this.params.daterange || this.params.daterange.length==0) ? "" : `&& time>='${this.params.daterange[0]} 00:00:00' && time<='${this.params.daterange[1]} 23:59:59'`;
    
      LogService.pager({
        pageSize: pageSize,
        pageNumber: pageNumber,
        condition: Hex.encode(`${c} && (${k})`),
        order: "time desc",
      }).then((pager) => {
        this.pager = pager;
      });
    },
  },
};
</script>
<style scoped>
</style>