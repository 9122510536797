export default{
    setUser: (user)=>{
        sessionStorage.setItem("_user",JSON.stringify(user));
    },
    getUser: ()=>{
        let userString = sessionStorage.getItem("_user");
        if(userString) return JSON.parse(userString);
        return null;
    },
    clearUser: ()=>{
        sessionStorage.removeItem("_user");
    }
}