import $http from "@/utils/http";

export default{
    pager: params => $http.get("/rest/sys/dict/pager", {params,loading: true}),
    list: params => $http.get("/rest/sys/dict", {params,loading: false}),
    create: dict => $http.post("/rest/sys/dict", dict, {loading: true}),
    update: dict => $http.put("/rest/sys/dict", dict, {loading: true}),
    remove: id => $http.delete(`/rest/sys/dict/${id}`, {loading: true}),
    exists: params => $http.get("/rest/sys/dict/exists", {params,loading: false}),

    listItem: dictId => $http.get(`/rest/sys/dict/${dictId}/item`, {loading: true}),
    createItem: (dictId,item) => $http.post(`/rest/sys/dict/${dictId}/item`, item, {loading: true}),
    removeItem: (dictId,itemId) => $http.delete(`/rest/sys/dict/${dictId}/item/${itemId}`, {loading: true}),
    existsItem: params => $http.get(`/rest/sys/dict/item/exists`, {params,loading: false}),
}