<template>
  <div class="nav">
    <div class="nav-content">
      <div class="logo">
        <img src="/image/web/logo.png"/>
      </div>
      <div class="menu">
        <div class="menu-item active">
          <a href="#">首页</a>
        </div>
        <div class="menu-item">
          <a href="#">案例</a>
        </div>
        <div class="menu-item">
          <a href="#">服务</a>
        </div>
        <div class="menu-item">
          <a href="#">我们</a>
        </div>
      </div>
      <div class="info">
        <img src="/image/web/phone.jpg"/>
        <div>
          <p>客服电话：<span style="color: rgb(47, 205, 144);">18669270883</span></p>
          <p>技术咨询：<span style="color: rgb(47, 205, 144);">18669270883</span></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
    name: 'Nav',
    data(){
      return {
        
      }
    },
    methods:{
      
    }
}
</script>
<style>
#app{
  overflow-y:auto;
  font-size: 14px;
}
</style>
<style scoped>
.nav{
  position: fixed;
  top:0;
  width:100%;
  height: 124px;
  background-color: white;
}
.nav .nav-content{
  display: flex;
  flex-direction: row;
  width:1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top:20px;
  align-items: center;
}
.nav-content .logo{
  flex-grow: 1;
  height: 84px;
  text-align: left;
}
.nav-content .menu{
  flex-grow: 3;
  height: 84px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.menu .menu-item{
  width:108px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-item>A{
  display: block;
  width: 108px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  color: #333;
}
.menu-item>A:hover,.menu>.active>A{
  background-color: rgb(47, 205, 144);
  color: white;
}
.nav-content .info{
  flex-grow: 1;
  border-left: solid rgb(47, 205, 144) 1px;
  height: 54px;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 15px;;
}
.info P{
  line-height: 1.5;
  color: #333;
  font-family: "Helvetica Neue",Helvetica, Arial,Tahoma,"Microsoft YaHei", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  outline: none;
  padding-left: 15px;
  margin: 0;
}
</style>