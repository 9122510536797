<template>
  <el-container style="height:100%;">
    <el-header>
      <Header/>
    </el-header>
    <el-container>
      <Menu/>
      <el-scrollbar style="margin:20px;">
        <el-main :is="module"/> 
        <el-footer>Copyright @ 2021-2022 xxx. All Rights Reserved</el-footer>
        </el-scrollbar>
    </el-container>
  </el-container>
</template>

<script>
import Header from '../components/Header';
import Menu from '../components/Menu';
export default {
    name: 'DefaultLayout',
    components:{Header,Menu},
    computed:{
      module(){
        if(this.$route.matched.length){
          return this.$route.name;
        }
        return 'Nopage';
      }
    }
}
</script>
<style scoped>
  .el-scrollbar{
    background-color: #EEEEEE;
  }
  .el-container {
    background-color: #EEEEEE;
  }
  .el-header{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    z-index: 100;
  }
  .el-main {
    background-color: #FFFFFF;
  }
  .el-footer{
    width:100%;
    background-color: #EEEEEE;
    color: rgba(0, 0, 0, 0.4);
    line-height: 60px;
    text-align: center;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
  }
  .el-scrollbar{
    width:100%;
  }
</style>