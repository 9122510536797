<template>
  <div>
    <el-row>
      <el-col :span="8" style="text-align: left;">
        <el-form :inline="true"  @submit.native.prevent>
          <el-form-item>
            <el-button type="primary" @click="showAddRootOrgDialog" class="el-icon-plus">根机构</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="16">
      </el-col>
    </el-row>

    <el-table
      :data="orgTree"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      :fit="true"
      border
      default-expand-all
    >
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="id" label="编码"></el-table-column>
      <el-table-column prop="id" label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            type="text"
            @click="showUpdateOrgDialog(scope.row)"
            >修改</el-button
          >
          <el-button
            icon="el-icon-plus"
            size="mini"
            type="text"
            @click="showAddChildOrgDialog(scope.row)"
            >子机构</el-button
          >
          <ConfirmButton :content="'确定删除['+scope.row.name+']吗？'" @ok="removeOrg(scope.row.id)"/>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form ref="orgForm" :model="org" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="上级机构" :span="12">
              <el-input v-model="org.pname" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上级编码" :span="12">
              <el-input v-model="org.pid" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :span="12"
              label="名称"
              prop="name"
            >
              <el-input v-model="org.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :span="12"
              label="编码"
              prop="id"
              :rules="[
                {
                  required: true,
                  message: '机构编码不能为空',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input v-if="oldOrg.id" v-model="org.id" disabled></el-input>
              <el-input v-else v-model="org.id"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="排序">
              <el-input v-model="org.orderNum" type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createOrUpdateOrg">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import OrgService from "@/api/sys/org";
import ArrayUtil from "@/utils/array-util";
import ConfirmButton from '@/components/common/ConfirmButton';
import Hex from '@/utils/hex';

export default {
  components:{ConfirmButton},
  data() {
    return {
      orgTree: [],
      dialogVisible: false,
      dialogTitle: '',
      org: {},
      oldOrg: {},
      rules:{
        name:[
          {
            required: true,
            message: '机构名称不能为空',
            trigger: 'blur',
          },{
            validator: (rule, value, callback) => { 
              OrgService.exists({condition: Hex.encode(`id!=${this.org.id} && pid=${this.org.pid} && name=${value}`)})
              .then((exists)=>{
                if(exists){
                  callback("机构名称重复");
                }else{
                  callback();
                }
              })
            },
            trigger: 'blur'
          }
        ],
        id:[
          {
            required: true,
            message: '机构编码不能为空',
            trigger: 'blur',
          },{
            validator: (rule, value, callback) => { 
              OrgService.exists({condition: Hex.encode(`id!=${this.org.id} && pid=${this.org.pid} && name=${value}`)})
              .then((exists)=>{
                if(exists){
                  callback("机构编码重复");
                }else{
                  callback();
                }
              })
            },
            trigger: 'blur'
          }
        ],
      }
    };
  },
  created: function () {
    this.loadOrgTree();
  },
  methods: {

    showAddRootOrgDialog: function(){
     this.org = {
        id:null,
        pid: null,
        name: '',
        orderNum:0
      };
      this.oldOrg = {};
      this.dialogTitle = '新增机构';
      this.dialogVisible = true;
    },
    showUpdateOrgDialog: function (org) {
      if(org.pid){
        OrgService.get(org.pid).then((pOrg)=>{
          org.pname = pOrg.name;
          this.org = {...org};
          this.oldOrg ={...org};
          this.dialogVisible = true;
          this.dialogTitle = '修改机构';
        });
      }else{
        this.org = {...org};
        this.oldOrg ={...org};
        this.dialogVisible = true;
        this.dialogTitle = '修改机构';
      }
    },
    showAddChildOrgDialog: function (pOrg) {
      this.org = {
        id:null,
        name: '',
        pid: pOrg.id,
        pname: pOrg.name,
        orderNum:0
      };
      this.oldOrg = {};
      this.dialogTitle = '新增机构';
      this.dialogVisible = true;
    },
    loadOrgTree: function () {
      OrgService.list({order: 'orderNum asc'}).then((orgs) => {
        const orgTree =
          ArrayUtil.toTree(
            orgs,
            { pId: "pid", nodes: "children" },
            null,
            "children"
          ) || [];
        this.orgTree = orgTree;
      });
    },
    removeOrg: function (id) {
      OrgService.remove(id).then(
          (r) => {
            this.$message.success("删除成功");
            this.loadOrgTree();
          },
          (err) => {}
      );
    },
    createOrUpdateOrg: function () {
      this.$refs.orgForm.validate((valid) =>{
        if(valid){
          if (!this.oldOrg.id) {
            OrgService.create(this.org).then(
              (r) => {
                this.$message.success("新增成功");
                this.dialogVisible = false;
                this.loadOrgTree();
              },
              (err) => {}
            );
          } else {
            OrgService.update(this.org).then(
              (r) => {
                this.$message.success("修改成功");
                this.dialogVisible = false;
                this.loadOrgTree();
              },
              (err) => {}
            );
          }
        }
      });
    },
  },
};
</script>
<style scoped>
</style>