/**
 * toTree(data,{nodeId:'id',pId:'p_id',text:'title'});
 */
const toTree = (list, opt,pId,childrenName) => {
    if(!childrenName) childrenName='nodes';

    function getRoot(list, pId) {
        var roots = [];
        if(pId){
            for (var i = 0; i < list.length; i++) {
                if (list[i].pId == pId) {
                    roots.push(list[i]);
                }
            }
        }else{
            for (var i = 0; i < list.length; i++) {
                if (!exists(list,list[i].pId)) {
                    roots.push(list[i]);
                }
            }
        }
        return roots;
    }
    function exists(list,id){
        for(var i=0;i<list.length;i++){
            if(list[i].id==id){
                return true;
            }
        }
        return false;
    }
    function buildTree(roots, list,childrenName) {
        for (var i = 0; i < roots.length; i++) {
            var root = roots[i];
            var children = [];
            for (var j = 0; j < list.length; j++) {
                var node = list[j];
                if (node.pId == root.id) {
                    children.push(node);
                }
            }
            root[childrenName] = children;
            buildTree(root[childrenName], list,childrenName);
        }

        return roots;
    }

    if (!list) return [];
    if (!opt) opt = {};

    for (var i = 0; i < list.length; i++) {
        for (var p in opt) {
            list[i][p] = list[i][opt[p]];
        }
        if (list[i].pId=='undefined' || list[i].pId=='null' || list[i].pId==null || list[i].pId==undefined || list[i].pId=='') list[i].pId = null;
    }

    var tree = buildTree(getRoot(list, pId), list,childrenName);

    return tree;
}

export default {
    toTree: toTree
}