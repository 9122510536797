<template>
  <el-popover placement="bottom-end" width="160" v-model="visible">
    <p>{{content}}</p>
    <div style="text-align: right; margin: 0">
      <el-button size="mini" type="text" @click="cancel"
        >取消</el-button
      >
      <el-button type="primary" size="mini" @click="ok"
        >确定</el-button
      >
    </div>
    <el-button
      slot="reference"
      icon="el-icon-delete"
      size="mini"
      type="text"
      style="margin-left:10px;"
      >删除</el-button
    >
  </el-popover>
</template>
<script>
export default {
  name: "ConfirmButton",
  props: {
    content: {
      type: String,
    }
  },
  data() {
    return {
      visible:false,
    };
  },
  methods: {
    cancel(){
      this.visible = false;
      this.$emit("cancel");
    },
    ok: function () {
      this.visible = false;
      this.$emit("ok");
    },
  },
};
</script>