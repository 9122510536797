export default {
  state: {
    name:"index"
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    name(state){
      return state.name
    }
  }
}