import $http from "@/utils/http";
import qs from 'qs'

export default{
    pager: params => $http.get("/rest/sys/role/pager", {params,loading: true}),
    list: params => $http.get("/rest/sys/role", {params,loading: false}),
    create: role => $http.post("/rest/sys/role", role, {loading: true}),
    update: role => $http.put("/rest/sys/role", role, {loading: true}),
    remove: id => $http.delete(`/rest/sys/role/${id}`, {loading: true}),
    exists: params => $http.get("/rest/sys/role/exists", {params,loading: false}),

    listRoleMenu: roleId => $http.get(`/rest/sys/role/${roleId}/menu`, {loading: true}),
    updateRoleMenu: (roleId,menuIds) => $http.post(`/rest/sys/role/${roleId}/menu`, qs.stringify({menuIds:menuIds.join(",")}), {loading: true}),
}