import axios from "axios";
import { Message, Loading } from "element-ui";
import Session from "@/utils/session";

const $http = axios.create({
    baseURL: BASE_URL, 
    timeout: 6000
});

var loadings = null;

$http.interceptors.request.use(
    config => {
        if(config.loading){
            if (loadings) loadings.close();
            loadings = Loading.service({
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.2)"
            });
        }
        let user = Session.getUser();
        if (user) {
            config.headers._token = user && user.token;     
        }
        return config;
    }
);

$http.interceptors.response.use(
    res => {
        if (loadings) loadings.close();
        let r = res.data;
        if (r && r.ok) {
            return r.data;
        } else if(r && r.code==401){
            Message({
                message: r.msg,
                type: "error",
                duration: 2000
            });
            window.location.href='/sys/login';
        }else if(r &&(r.success || r.resultCode==200)){
            return r;
        }else{
            Message({
                message: r.msg,
                type: "error",
                duration: 2000
            });
            return Promise.reject(r);
        }
    },
    err => {
        if (loadings) loadings.close();
        Message({
            message: "服务器请求失败，请稍后再试",
            type: "error",
            duration: 2000
        });
        return Promise.reject({
            ok:false,msg:'服务器请求失败，请稍后再试',code:404
        });
    }
);

export default $http;
