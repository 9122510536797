<template>
  <el-container>
    <div class="login-bg">
      <img src="/image/sys/login/objects-big.png" class="object" style="left:88.54%;top:39%;"/>
      <img src="/image/sys/login/objects-mid.png" class="object" style="left:75px;top:568px;"/>
      <img src="/image/sys/login/objects-mini.png" class="object" style="left:71.7%;top:75.1%;"/>
      <img src="/image/sys/login/logo-marker-mid.png" class="marker" style="left:12.5%;top:-50px;"/>
      <img src="/image/sys/login/logo-marker-mini.png" class="marker" style="right:12.5%;bottom:0px;"/>
      <el-row style="height:26.33%;">
        <el-col style="text-align:left;" :push="4">
          <img src="/image/sys/login/logo@2x.png" style="width:47.6%;heigh:auto;margin-top: 36px;"/>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <p>&nbsp;</p>
        </el-col>
        <el-col :span="8">
          <img src="/image/sys/login/login-pic.png" style="width:100%;height:100%;"/>
        </el-col>
        <el-col :span="8">
          <el-main :is="module"></el-main>
        </el-col>
        <el-col :span="4">
          <p>&nbsp;</p>
        </el-col>
      </el-row>
      <el-row class="copyright">
        <el-col><span>技术支持电话：0875-xxxxxxxx</span><span>邮箱：xxxxxx@126.com</span></el-col>
      </el-row>
    </div>
  </el-container>
</template>

<script>
export default {
    name: 'LoginLayout',
    computed:{
      module(){
        return this.$route.name;
      }
    }
}
</script>
<style scoped>
.el-container{
  width:100%;
  height:100%;
  background-repeat: no-repeat;
  background-position:center;
  background-color: #FFF;
  
}
.login-bg{
  width:75%;height:710px;
  background-image: url(/image/sys/login/bg.png);
  background-position: left top;
  background-size: 1440px 710px;
  background-repeat: no-repeat;
  margin-left:auto;
  margin-right: auto;
  margin-top:60px;
}
.object{
  position: relative;
  float: left;
}
.marker{
  position: absolute;
}
.copyright{
  width: 100%;
  height: 100px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 14px;
  color: #909399;
  opacity: 1;
  margin-top:300px;
}
</style>