<template>
    <div>
        <el-row>
            <el-col :span="8" style="text-align: left;">
                <el-button type="primary" @click="showAddDialog" class="el-icon-plus">用户</el-button>
            </el-col>
            <el-col :span="16" style="text-align: right;">
                <el-form :inline="true" :model="params" @submit.native.prevent>
                    <el-form-item>
                        <el-input v-model="params.keyword" placeholder="姓名/帐号/电话" @keyup.enter.native="search(1)"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search(1)">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="6">
                <el-table ref="orgTree" :data="orgTree" row-key="id" :fit="true" border default-expand-all
                 highlight-current-row @current-change="currentOrgChange">
                    <el-table-column prop="name" label="机构名称"></el-table-column>
                </el-table>
            </el-col>
            <el-col :span="18" style="padding-left:20px;">
                <el-table :data="pager.rows">
                    <el-table-column prop="username" label="帐号"></el-table-column>
                    <el-table-column prop="realname" label="姓名"></el-table-column>
                    <el-table-column prop="phone" label="电话"></el-table-column>
                    <el-table-column prop="roleNamesString" label="角色"></el-table-column>
                    <el-table-column prop="id" label="状态" width="100" align="center">
                        <template slot-scope="scope">
                            <el-switch :value="scope.row.enabled" @change="enabled(!scope.row.enabled,scope.row.id)" :active-value="true" :inactive-value="false">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="操作" width="200" align="center">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" size="mini" type="text" @click="showUpdateDialog(scope.row)">修改</el-button>
                            <ConfirmButton :content="'确定删除['+scope.row.realname+']吗？'" @ok="remove(scope.row.id)"/>
                        </template>
                    </el-table-column>
                </el-table>
                <Pager :pager="pager" @change="search"/>
            </el-col>
        </el-row>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
          <el-form ref="userForm" :model="user" :rules="rules" label-width="80px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="帐号" prop="username">
                  <el-input v-if="user.id" v-model="user.username" disabled></el-input>
                  <el-input v-else v-model="user.username"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属机构" prop="orgName">
                  <el-input v-model="user.orgName" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="姓名" prop="realname">
                  <el-input v-model="user.realname"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电话" prop="phone">
                  <el-input v-model="user.phone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="邮箱" prop="email">
                  <el-input v-model="user.email"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证" prop="idcard">
                  <el-input v-model="user.idcard"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item v-if="user.id" label="密码" prop="password">
                  <el-input  v-model="user.password" type="password"></el-input>
                </el-form-item>
                <el-form-item v-else label="密码" prop="password"
                :rules="[{required: true,message: '密码不能为空',trigger: 'blur'}]">
                  <el-input  v-model="user.password" type="password"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="确认密码" prop="password2">
                  <el-input v-model="user.password2" type="password"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="角色" prop="roleIds">
                   <el-select style="width:100%;" v-model="user.roleIds" multiple default-first-option placeholder="请选择角色">
                        <el-option
                            v-for="role in canAssignRoles" :key="role.id" :label="role.name" :value="role.id">
                        </el-option>
                    </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="描述">
                  <el-input v-model="user.description" type="textarea" :rows="3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <el-form-item label="激活" prop="enabled">
                  <el-switch v-model="user.enabled" :active-value="true" :inactive-value="false">
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="createOrUpdate">确 定</el-button>
          </span>
        </el-dialog>

    </div>
</template>

<script>

import UserService from "@/api/sys/user";
import OrgService from "@/api/sys/org";
import ConfirmButton from '@/components/common/ConfirmButton';
import Pager from '@/components/common/Pager';
import Hex from '@/utils/hex';
import Validator from '@/utils/validator';
import ArrayUtil from "@/utils/array-util";

export default {
    components:{ConfirmButton,Pager},
    data(){
        return{
            orgTree:[],
            org:{},
            pager:{
                rows:[],
                total:0,
                pageSize: 10,
                pageNumber: 1
            },
            params:{
                keyword: ''
            },

            dialogTitle:'',
            dialogVisible: false,
            canAssignRoles: [],
            user:{},
            rules:{
                username:[
                {
                  required: true,
                  message: '帐号不能为空',
                  trigger: 'blur',
                },{
                  validator: (rule, value, callback) => { 
                    UserService.exists({condition: Hex.encode(`id!=${this.user.id} && username=${value}`)})
                    .then((exists)=>{
                      if(exists){
                        callback("帐号已存在");
                      }else{
                        callback();
                      }
                    })
                  },
                  trigger: 'blur'
                }
              ],
              phone:[
                {
                  validator: (rule, value, callback) => { 
                    if(!Validator.phone(value)){
                        callback("电话不合法");
                    }else{
                        callback();
                    }
                  },
                  trigger: 'blur'
                }  
              ],
              idcard:[
                {
                  validator: (rule, value, callback) => { 
                    if(!Validator.idcard(value)){
                        callback("身份证不合法");
                    }else{
                        callback();
                    }
                  },
                  trigger: 'blur'
                }  
              ],
              email:[
                {
                  validator: (rule, value, callback) => { 
                    if(!Validator.email(value)){
                        callback("邮箱不合法");
                    }else{
                        callback();
                    }
                  },
                  trigger: 'blur'
                }  
              ],
              password2:[
                  {
                      validator:(rule,value,callback)=>{
                          if((value || this.user.password) && value!=this.user.password){
                              callback("两次密码不一致");
                          }else{
                              callback();
                          }
                      }
                  }
              ]
            },
        }
    },
    created: function () {
        this.loadOrgTree();
    },
    methods:{
        currentOrgChange(org){
            this.org = org;
            this.search(1);
        },
        showAddDialog(){
          this.dialogTitle = "新增用户";
          this.dialogVisible = true;
          this.$refs.userForm && this.$refs.userForm.resetFields();
          this.user = {
            id: null,
            orgId: this.org.id,
            orgName: this.org.name,
            username: '',
            realname:'',
            phone:'',
            email:'',
            idcard:'',
            password:'',
            password2:'',
            description: ''
          };
          UserService.canAssignRoles().then(
              (canAssignRoles)=>{
                  this.canAssignRoles = canAssignRoles;
              }
          );
        },
        showUpdateDialog(user){
          this.dialogTitle = "修改用户";
          this.dialogVisible = true;
          this.$refs.userForm && this.$refs.userForm.resetFields();
          this.user = {...user};
          this.user.password = '';
          UserService.canAssignRoles().then(
              (canAssignRoles)=>{
                  this.canAssignRoles = canAssignRoles;
              }
          );
        },
        enabled(enabled,userId){
            UserService.enabled(userId,enabled).then((r) => {
                    this.$message.success(enabled?'激活成功':'禁用成功');
                    this.search();
                },
                (err) => {}
            );
        },
        createOrUpdate(){
          this.$refs.userForm.validate((valid) =>{
            if(valid){
              if (!this.user.id) {
                UserService.create(this.user).then(
                  (r) => {
                    this.$message.success("新增成功");
                    this.dialogVisible = false;
                    this.search();
                  },
                  (err) => {}
                );
              } else {
                UserService.update(this.user).then(
                  (r) => {
                    this.$message.success("修改成功");
                    this.dialogVisible = false;
                    this.search();
                  },
                  (err) => {}
                );
              }
            }
          });
        },
        remove(id){
          UserService.remove(id).then(
              (r) => {
                this.$message.success("删除成功");
                this.search();
              },
              (err) => {}
          );
        },
        search(pageNumber,pageSize) {
            if(!pageNumber) pageNumber = this.pager.pageNumber;
            if(!pageSize) pageSize = this.pager.pageSize;
            UserService.pager({
                pageSize: pageSize,
                pageNumber: pageNumber,
                condition: Hex.encode(`orgId=${this.org.id} && (username=%${this.params.keyword}% || realname=%${this.params.keyword}% || phone=%${this.params.keyword}%)`)
            }).then((pager) => {
                this.pager = pager;
            });
        },
        loadOrgTree() {
            OrgService.list({order: 'orderNum asc'}).then((orgs) => {
                const orgTree =
                ArrayUtil.toTree(
                    orgs,
                    { pId: "pid", nodes: "children" },
                    null,
                    "children"
                ) || [];
                this.orgTree = orgTree;
                if(this.orgTree && this.orgTree.length){
                    this.$refs.orgTree.setCurrentRow(this.orgTree[0]);
                }
            });
        },
    }
}
</script>
<style scoped>
</style>