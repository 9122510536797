<template>
  <div>
    <el-row>
      <el-col :span="8" style="text-align: left">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-button
              type="primary"
              @click="showAddRootMenuDialog"
              class="el-icon-plus"
              >根菜单</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="16"> </el-col>
    </el-row>
    <el-table
      :data="menuTree"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      :fit="true"
      border
      default-expand-all
    >
      <el-table-column prop="name" label="名称">
        <template slot-scope="scope">
          <span><i :class="scope.row.icon"></i>&nbsp;{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="url" label="URL">
        <template slot-scope="scope">
          <span>{{ scope.row.url }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="display"
        label="是否显示"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.display" effect="plain" type="success"
            >是</el-tag
          >
          <el-tag v-else effect="plain" type="info">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            type="text"
            @click="showUpdateMenuDialog(scope.row)"
            >修改</el-button
          >
          <el-button
            icon="el-icon-plus"
            size="mini"
            type="text"
            @click="showAddChildMenuDialog(scope.row)"
            >子菜单</el-button
          >
          <ConfirmButton
            :content="'确定删除[' + scope.row.name + ']吗？'"
            @ok="removeMenu(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form ref="menuForm" :model="menu" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="父菜单" :span="12">
              <el-input v-model="menu.pname" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :span="12" label="名称" prop="name">
              <el-input v-model="menu.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="URL">
              <el-input v-model="menu.url"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="图标">
              <el-autocomplete
                v-model="menu.icon"
                :fetch-suggestions="iconSearch"
                placeholder="请输入或选择图标"
                @select="iconSelect"
              >
                <i :class="menu.icon+' el-input__icon'" slot="suffix"> </i>
                <template slot-scope="{ item }">
                  <span><i :class="'iconfont icon-'+item.font_class"></i>&nbsp;{{ item.name }}</span>
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="显示">
              <el-select v-model="menu.display">
                <el-option label="显示" :value="true"></el-option>
                <el-option label="隐藏" :value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序">
              <el-input v-model="menu.orderNum" type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="menu.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createOrUpdateMenu">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import MenuService from "@/api/sys/menu";
import ArrayUtil from "@/utils/array-util";
import ConfirmButton from "@/components/common/ConfirmButton";
import Hex from "@/utils/hex";
import axios from "axios";

export default {
  components: { ConfirmButton },
  data() {
    return {
      icons: [],
    
      menuTree: [],
      dialogVisible: false,
      dialogTitle: "",
      menu: {},
      rules: {
        name: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              MenuService.exists({
                condition: Hex.encode(
                  `id!=${this.menu.id} && pid=${this.menu.pid} && name=${value}`
                ),
              }).then((exists) => {
                if (exists) {
                  callback("名称重复");
                } else {
                  callback();
                }
              });
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  created: function () {
    this.loadIcons();
    this.loadMenuTree();
  },
  methods: {
    loadIcons() {
      axios.get("/font/iconfont/iconfont.json").then(
        (response) => {
          this.icons = response.data.glyphs;
        },
        (response) => {
          Message.info("加载图标数据失败");
        }
      );
    },
    iconSearch(queryString, cb) {
      cb(this.icons);
    },
    iconSelect(item){
      let menu = this.menu;
      menu.icon = 'iconfont icon-'+item.font_class;
      this.menu = {...menu};
    },
    showAddRootMenuDialog: function () {
      this.menu = {
        id: null,
        pid: null,
        pname: "无",
        display: true,
        orderNum: 0,
      };
      this.dialogTitle = "新增菜单";
      this.dialogVisible = true;
    },
    showUpdateMenuDialog: function (menu) {
      this.dialogVisible = true;
      this.dialogTitle = "修改菜单";
      this.menu = menu;
    },
    showAddChildMenuDialog: function (pMenu) {
      this.menu = {
        id: null,
        pid: pMenu.id,
        pname: pMenu.name,
        display: true,
        orderNum: 0,
      };
      this.dialogTitle = "新增菜单";
      this.dialogVisible = true;
    },
    loadMenuTree: function () {
      MenuService.list().then((menus) => {
        const menuTree =
          ArrayUtil.toTree(
            menus,
            { pId: "pid", nodes: "children" },
            null,
            "children"
          ) || [];
        this.menuTree = menuTree;
      });
    },
    removeMenu: function (id) {
      MenuService.remove(id).then(
        (r) => {
          this.$message.success("删除成功");
          this.loadMenuTree();
        },
        (err) => {}
      );
    },
    createOrUpdateMenu: function () {
      this.$refs.menuForm.validate((valid) => {
        if (valid) {
          if (!this.menu.id) {
            MenuService.create(this.menu).then(
              (r) => {
                this.$message.success("新增成功");
                this.dialogVisible = false;
                this.loadMenuTree();
              },
              (err) => {}
            );
          } else {
            MenuService.update(this.menu).then(
              (r) => {
                this.$message.success("修改成功");
                this.dialogVisible = false;
                this.loadMenuTree();
              },
              (err) => {}
            );
          }
        }
      });
    },
  },
};
</script>
<style scoped>
</style>