import $http from "@/utils/http";
import qs from 'qs'

export default{
    pager: (params) => $http.get("/rest/sys/user/pager", {params,loading: true}),
    create: user => $http.post("/rest/sys/user", user, {loading: true}),
    update: user => $http.put("/rest/sys/user", user, {loading: true}),
    enabled: (id,enabled) => $http.put(`/rest/sys/user/${id}/enabled`, qs.stringify({enabled}), {loading: true,}),
    remove: id => $http.delete(`/rest/sys/user/${id}`, {loading: true}),
    canAssignRoles: () => $http.get("/rest/sys/user/role/canAssign", {loading: true}),
    exists: params => $http.get("/rest/sys/user/exists", {params,loading: false}),
    resetPwd: (opassword,npassword) => $http.post("/rest/sys/user/resetPwd", qs.stringify({opassword,npassword}), {loading: true}),
}