<template>
  <div>
    <el-row>
      <el-col :span="24" style="text-align: right">
        <el-form :inline="true" :model="params" @submit.native.prevent>
          <el-form-item>
            <el-select v-model="params.enabled" placeholder="开启状态">
              <el-option label="全部" value=""></el-option>
              <el-option label="开启" value="true"></el-option>
              <el-option label="禁用" value="false"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="params.keyword"
              placeholder="接口/名称/类型"
              @keyup.enter.native="search(1)"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search(1)">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <el-table :data="pager.rows">
      <el-table-column prop="logger" label="接口"></el-table-column>
      <el-table-column prop="name" label="名称">
        <template slot-scope="scope">
          <el-input v-model="scope.row.name" style="width: 80%" />
          <el-button
            type="text"
            @click="updateName(scope.row.name, scope.row.id)"
            style="margin-left: 20px"
            >保存</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" width="150">
        <template slot-scope="scope">
          <el-input v-model="scope.row.type" style="width: 60%"/>
          <el-button
            type="text"
            @click="updateType(scope.row.type, scope.row.id)"
            style="margin-left: 20px"
            >保存</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="id" label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.enabled"
            @change="enabled(!scope.row.enabled, scope.row.id)"
            :active-value="true"
            :inactive-value="false"
          >
          </el-switch>
        </template>
      </el-table-column>
    </el-table>
    <Pager :pager="pager" @change="search" />
  </div>
</template>

<script>
import LogService from "@/api/sys/log";
import ConfirmButton from "@/components/common/ConfirmButton";
import Pager from "@/components/common/Pager";
import Hex from "@/utils/hex";

export default {
  components: { ConfirmButton, Pager },
  data() {
    return {
      pager: {
        rows: [],
        total: 0,
        pageSize: 10,
        pageNumber: 1,
      },
      params: {
        keyword: "",
        enabled: "",
      },
    };
  },
  created() {
    this.search(1);
  },
  methods: {
    enabled(enabled, logconfigId) {
      LogService.configEnabled(logconfigId, enabled).then(
        (r) => {
          this.$message.success(enabled ? "启用成功" : "禁用成功");
          this.search();
        },
        (err) => {}
      );
    },
    updateName(name, logconfigId) {
      LogService.configUpdateName(logconfigId, name).then(
        (r) => {
          this.$message.success("修改成功");
          this.search();
        },
        (err) => {}
      );
    },
    updateType(type, logconfigId) {
      LogService.configUpdateType(logconfigId, type).then(
        (r) => {
          this.$message.success("修改成功");
          this.search();
        },
        (err) => {}
      );
    },
    search(pageNumber, pageSize) {
      if (!pageNumber) pageNumber = this.pager.pageNumber;
      if (!pageSize) pageSize = this.pager.pageSize;
      LogService.configPager({
        pageSize: pageSize,
        pageNumber: pageNumber,
        condition: Hex.encode(
          this.params.enabled ===""
            ? `logger=%${this.params.keyword}% || name=%${this.params.keyword}% || type=%${this.params.keyword}%`
            : `enabled=${this.params.enabled} && (logger=%${this.params.keyword}% || name=%${this.params.keyword}% || type=%${this.params.keyword}%)`
        ),
      }).then((pager) => {
        this.pager = pager;
      });
    },
  },
};
</script>
<style scoped>
</style>