<template>
    <div style="overflow-x:hidden;">
        <Nav/>
        <div :is="module"></div>
    </div>
</template>

<script>
import Nav from '../components/web/Nav';
export default {
    name: 'WebLayout',
    components:{Nav},
    computed:{
      module(){
        return this.$route.name;
      }
    }
}
</script>
<style scoped>
.el-container{
  width:100%;
  height:100%;
  background-repeat: no-repeat;
  background-position:center;
  background-color: white;
  background-size:100% 100%;-moz-background-size:100% 100%;
}
</style>