import{ 
    createNamespacedHelpers,
    mapState as mapRootState,
    mapGetters as mapRootGetters,
    mapMutations as mapRootMutations,
    mapActions as mapRootActions
}from'vuex'

export {mapRootState,mapRootGetters,mapRootMutations,mapRootActions};

let path = window.location.pathname;
if(path.startsWith("/")){
    path = path.substring(1);
}
const namespace = path;
console.log("current namespace:"+namespace)
export let {mapState, mapGetters, mapMutations, mapActions} = createNamespacedHelpers(namespace);

export let rootStore = {
    state: {},
    getters:{},
    mutations: {},
    actions: {
    },
    modules: {
    }
  }
export default {
    rootStore, 
    mapRootState, mapRootGetters, mapRootMutations, mapRootActions,
    mapState, mapGetters, mapMutations, mapActions
}