<template>
    <div>
        <el-row>
            <el-col :span="8" style="text-align: left;">
                <el-button type="primary" @click="showAddDialog" class="el-icon-plus">字典</el-button>
            </el-col>
            <el-col :span="16" style="text-align: right;">
                <el-form :inline="true" :model="params" @submit.native.prevent>
                    <el-form-item>
                        <el-input v-model="params.keyword" placeholder="名称/编码/描述" @keyup.enter.native="search(1)"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search(1)">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-table
            :data="pager.rows"
            style="width: 100%">
            <el-table-column
                prop="name"
                label="名称"
                width="180">
            </el-table-column>
            <el-table-column
                prop="code"
                label="编码"
                width="180">
            </el-table-column>
            <el-table-column
                prop="description"
                label="描述">
            </el-table-column>
            <el-table-column prop="id" label="操作" width="300" align="center">
              <template slot-scope="scope">
                <el-button
                  icon="el-icon-edit"
                  :underline="false"
                  size="mini"
                  type="text"
                  @click="showUpdateDialog(scope.row)"
                  >修改</el-button
                >
                <el-button
                  icon="el-icon-edit"
                  :underline="false"
                  size="mini"
                  type="text"
                  @click="showItemsDialog(scope.row)"
                  >管理条目</el-button
                >
                <ConfirmButton :content="'确定删除['+scope.row.name+']吗？'" @ok="remove(scope.row.id)"/>
              </template>
            </el-table-column>
        </el-table>
        <Pager :pager="pager" @change="search"/>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
          <el-form ref="dictForm" :model="dict" :rules="rules" label-width="80px">
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :span="12"
                  label="名称"
                  prop="name"
                >
                  <el-input v-model="dict.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :span="12"
                  label="编码"
                  prop="code"
                >
                  <el-input v-model="dict.code"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="描述">
                  <el-input v-model="dict.description" type="textarea" :rows="3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="createOrUpdate">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog title="管理条目" :visible.sync="itemDialogVisible" width="30%">
            <el-table
              :data="items"
              style="width: 100%">
              <el-table-column prop="ilabel" label="名称"></el-table-column>
              <el-table-column prop="ivalue" label="值"></el-table-column>
              <el-table-column prop="id" label="操作" width="300" align="center">
                <template slot-scope="scope">
                  <ConfirmButton :content="'确定删除['+scope.row.ilabel+']吗？'" @ok="removeItem(scope.row.id)"/>
                </template>
              </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="showItemAddDialog()" class="el-icon-plus">增加</el-button>
            <el-button @click="itemDialogVisible = false">取 消</el-button>
          </span>
        </el-dialog>

        <el-dialog title="新增条目" :visible.sync="itemAddDialogVisible" width="20%">
          <el-form ref="itemForm" :model="item" :rules="itemRules" label-width="80px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="名称" prop="ilabel">
                  <el-input v-model="item.ilabel"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="值" prop="ivalue">
                  <el-input v-model="item.ivalue"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="序号" prop="orderNum" type="number">
                  <el-input v-model="item.orderNum"></el-input>
                </el-form-item>
              </el-col>
            </el-row>  
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="itemAddDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="createItem()">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>

import DictService from "@/api/sys/dict";
import ConfirmButton from '@/components/common/ConfirmButton';
import Pager from '@/components/common/Pager';
import Hex from '@/utils/hex';
export default {
    components:{ConfirmButton,Pager},
    data(){
        return{
            pager:{
                rows:[],
                total:0,
                pageSize: 10,
                pageNumber: 1
            },
            params:{
                keyword: ''
            },
            dialogVisible: false,
            dialogTitle: '',
            itemDialogVisible: false,
            itemAddDialogVisible: false,
            dict:{

            },
            currentDict: null,
            item:{

            },
            items:[],
            rules:{
              name:[
                {
                  required: true,
                  message: '名称不能为空',
                  trigger: 'blur',
                }
              ],
              code:[
                {
                  required: true,
                  message: '编码不能为空',
                  trigger: 'blur',
                },{
                  validator: (rule, value, callback) => { 
                    DictService.exists({condition: Hex.encode(`id!=${this.dict.id} && code=${value}`)})
                    .then((exists)=>{
                      if(exists){
                        callback("编码重复");
                      }else{
                        callback();
                      }
                    })
                  },
                  trigger: 'blur'
                }
              ],
            },
            itemRules:{
              ilabel:[
                {
                  required: true,
                  message: '名称不能为空',
                  trigger: 'blur',
                },{
                  validator: (rule, value, callback) => { 
                    DictService.existsItem({condition: Hex.encode(`dictId==${this.currentDict.id} && ilabel=${value}`)})
                    .then((exists)=>{
                      if(exists){
                        callback("名称重复");
                      }else{
                        callback();
                      }
                    })
                  },
                  trigger: 'blur'
                }
              ],
              ivalue:[
                {
                  validator: (rule, value, callback) => { 
                    DictService.existsItem({condition: Hex.encode(`dictId=${this.currentDict.id} && ivalue=${value}`)})
                    .then((exists)=>{
                      if(exists){
                        callback("值重复");
                      }else{
                        callback();
                      }
                    })
                  },
                  trigger: 'blur'
                }
              ],
            }
        }
    },
    created: function () {
      this.search(1);
    },
    methods:{
        showAddDialog(){
          this.dialogTitle = "新增字典";
          this.dialogVisible = true;
          this.dict = {
            id: null,
            name: '',
            code: '',
            description: ''
          };
        },
        showUpdateDialog(dict){
          this.dialogTitle = "修改字典";
          this.dialogVisible = true;
          this.dict = {...dict};
        },
        showItemsDialog(dict){
          this.currentDict = dict;
          this.itemDialogVisible = true;
          this.listItem(this.currentDict.id);
        },
        showItemAddDialog(){
          this.item = {};
          this.itemAddDialogVisible = true;
        },
        createOrUpdate(){
          this.$refs.dictForm.validate((valid) =>{
            if(valid){
              if (!this.dict.id) {
                DictService.create(this.dict).then(
                  (r) => {
                    this.$message.success("新增成功");
                    this.dialogVisible = false;
                    this.search();
                  },
                  (err) => {}
                );
              } else {
                DictService.update(this.dict).then(
                  (r) => {
                    this.$message.success("修改成功");
                    this.dialogVisible = false;
                    this.search();
                  },
                  (err) => {}
                );
              }
            }
          });
        },
        remove(id){
          DictService.remove(id).then(
              (r) => {
                this.$message.success("删除成功");
                this.search();
              },
              (err) => {}
          );
        },
        search: function (pageNumber,pageSize) {
            if(!pageNumber) pageNumber = this.pager.pageNumber;
            if(!pageSize) pageSize = this.pager.pageSize;
            DictService.pager({
                pageSize: pageSize,
                pageNumber: pageNumber,
                condition: Hex.encode(`name=%${this.params.keyword}% || code=%${this.params.keyword}% || description=%${this.params.keyword}%`)
            }).then((pager) => {
                this.pager = pager;
            });
        },
        listItem(dictId){
          DictService.listItem(dictId).then((items)=>{
            this.items = items;
          }); 
        },
        createItem(){
          this.$refs.itemForm.validate((valid) =>{
            if(valid){
              DictService.createItem(this.currentDict.id,this.item).then(
                (r) => {
                  this.$message.success("新增成功");
                  this.itemAddDialogVisible = false;
                  this.listItem(this.currentDict.id);
                },
                (err) => {}
              ); 
            }
          });         
        },
        removeItem(id){
          DictService.removeItem(this.currentDict.id,id).then(
              (r) => {
                this.$message.success("删除成功");
                this.listItem(this.currentDict.id);
              },
              (err) => {}
          );
        },
    }
}
</script>