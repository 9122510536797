<template>
  <el-row>
    <div class="login-title">账号登录</div>
    <el-form ref="loginForm" :model="user" :rules="rules">
      <el-form-item  prop="username">
        <el-input v-model="user.username" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item  prop="password">
        <el-input v-model="user.password" placeholder="密码" show-password></el-input>
      </el-form-item>
      <el-form-item  prop="checkCode">
        <div class="item-inline">
          <el-input v-model="user.checkCode" placeholder="验证码" @keyup.enter.native="login"></el-input>
          <el-image class="check-code" :src="checkCodeUrl" @click="refreshTicket"></el-image>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button class="login-btn" type="primary" @click="login">登录</el-button>
      </el-form-item>
    </el-form>
  </el-row>
</template>

<script>
import Session from '@/utils/session';
import UUID from '@/utils/uuid';
import LoginService from '@/api/sys/login';

export default {
  data(){
    return {
      user:{
        username:'admin',
        password:'111111',
        checkCode: '',
        ticket:''
      },
      rules: {
        username: [
          { required: true, message: '请输入帐号', trigger: 'blur' },
          { min: 1, max: 10, message: '请输入正确的帐号', trigger: 'blur'}
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        checkCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
      }
    }
  },
  computed: {
    checkCodeUrl() {
      return `${BASE_URL}/rest/sys/login/checkCode?ticket=${this.user.ticket}`;
    },
  },
  created() {
    this.refreshTicket();
  },
  methods:{
    refreshTicket() {
      this.user.ticket = UUID.randomUUID();
    },
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          LoginService.login(this.user).then((user) => {
            Session.setUser(user);
            LoginService.userMenu().then((menus) => {
              menus = menus.filter(menu => menu.display);
              user.menus = menus;
              Session.setUser(user);
              this.$router.push({ path: '/index' });
            }, (err) => {
              throw err;
            });
          }, (err) => {
            this.refreshTicket();
          });
        }
      });
    }
  }
};
</script>

<style scoped>
  .login-title{
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 24px;
    color: #214425;
    margin-left: 25%;
    text-align: left;
  }
  .el-form{
    margin-top: 20px;
    background-color: white;
    border-radius: 8px;
    margin-left: 25%;
    margin-right: 10%;
  }
  .item-inline{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .check-code{
    cursor: pointer;
    height:32px;
    margin-left: 12px;
  }
  .login-btn{
    width:100%;
  }
</style>