<template>
  <div>
    <el-row>
      <el-col :span="8" style="text-align: left;">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-button type="primary" @click="showAddRootCategoryDialog" class="el-icon-plus">根分类</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="16">
        
      </el-col>
    </el-row>

    <el-table
      :data="categoryTree"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      :fit="true"
      border
      default-expand-all
    >
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="id" label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            :underline="false"
            size="mini"
            type="text"
            @click="showUpdateCategoryDialog(scope.row)"
            >修改</el-button
          >
          <el-button
            icon="el-icon-plus"
            :underline="false"
            size="mini"
            type="text"
            @click="showAddChildCategoryDialog(scope.row)"
            >子分类</el-button
          >
          <ConfirmButton :content="'确定删除['+scope.row.name+']吗？'" @ok="removeCategory(scope.row.id)"/>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form ref="categoryForm" :model="category" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="上级分类">
              <el-input v-model="category.pname" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="名称"
              prop="name"
            >
              <el-input v-model="category.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="排序">
              <el-input v-model="category.orderNum" type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createOrUpdateCategory">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import CategoryService from "@/api/sys/category";
import ArrayUtil from "@/utils/array-util";
import ConfirmButton from '@/components/common/ConfirmButton';
import Hex from '@/utils/hex';

export default {
  components:{ConfirmButton},
  data() {
    return {
      categoryTree: [],
      dialogVisible: false,
      dialogTitle: '',
      category: {},
      rules:{
        name:[
          {
            required: true,
            message: '名称不能为空',
            trigger: 'blur',
          },{
            validator: (rule, value, callback) => { 
              CategoryService.exists({condition: Hex.encode(`id!=${this.category.id} && pid=${this.category.pid} && name=${value}`)})
              .then((exists)=>{
                if(exists){
                  callback("名称重复");
                }else{
                  callback();
                }
              })
            },
            trigger: 'blur'
          }
        ],
      }
    };
  },
  created: function () {
    this.loadCategoryTree();
  },
  methods: {

    showAddRootCategoryDialog: function(){
     this.category = {
        id:null,
        pid: null,
        name: '',
        orderNum:0
      };
      this.dialogTitle = '新增分类';
      this.dialogVisible = true;
    },
    showUpdateCategoryDialog: function (category) {
      if(category.pid){
        CategoryService.get(category.pid).then((pCategory)=>{
          category.pname = pCategory.name;
          this.category = {...category};
          this.dialogVisible = true;
          this.dialogTitle = '修改分类';
        });
      }else{
        this.category = {...category};
        this.dialogVisible = true;
        this.dialogTitle = '修改分类';
      }
    },
    showAddChildCategoryDialog: function (pCategory) {
      this.category = {
        id:null,
        name: '',
        pid: pCategory.id,
        pname: pCategory.name,
        orderNum:0
      };
      this.dialogTitle = '新增分类';
      this.dialogVisible = true;
    },
    loadCategoryTree: function () {
      CategoryService.list({order: 'orderNum asc'}).then((categorys) => {
        const categoryTree =
          ArrayUtil.toTree(
            categorys,
            { pId: "pid", nodes: "children" },
            null,
            "children"
          ) || [];
        this.categoryTree = categoryTree;
      });
    },
    removeCategory: function (id) {
      CategoryService.remove(id).then(
          (r) => {
            this.$message.success("删除成功");
            this.loadCategoryTree();
          },
          (err) => {}
      );
    },
    createOrUpdateCategory: function () {
      this.$refs.categoryForm.validate((valid) =>{
        if(valid){
          if (!this.category.id) {
            CategoryService.create(this.category).then(
              (r) => {
                this.$message.success("新增成功");
                this.dialogVisible = false;
                this.loadCategoryTree();
              },
              (err) => {}
            );
          } else {
            CategoryService.update(this.category).then(
              (r) => {
                this.$message.success("修改成功");
                this.dialogVisible = false;
                this.loadCategoryTree();
              },
              (err) => {}
            );
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>