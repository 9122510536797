<template>
    <div>
        <el-row>
            <el-col :span="8" style="text-align: left;">
                <el-button type="primary" @click="showAddDialog" class="el-icon-plus">角色</el-button>
            </el-col>
            <el-col :span="16" style="text-align: right;">
                <el-form :inline="true" :model="params" @submit.native.prevent>
                    <el-form-item>
                        <el-input v-model="params.keyword" placeholder="名称/编码/描述" @keyup.enter.native="search(1)"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search(1)">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-table
            :data="pager.rows"
            style="width: 100%">
            <el-table-column
                prop="name"
                label="名称"
                width="180">
            </el-table-column>
            <el-table-column
                prop="code"
                label="编码"
                width="180">
            </el-table-column>
            <el-table-column
                prop="description"
                label="描述">
            </el-table-column>
            <el-table-column prop="id" label="操作" width="300" align="center">
              <template slot-scope="scope">
                <el-button
                  icon="el-icon-edit"
                  :underline="false"
                  size="mini"
                  type="text"
                  @click="showUpdateDialog(scope.row)"
                  >修改</el-button
                >
                <el-button
                  icon="el-icon-key"
                  :underline="false"
                  size="mini"
                  type="text"
                  @click="showPowerDialog(scope.row)"
                  >权限配置</el-button
                >
                <ConfirmButton :content="'确定删除['+scope.row.name+']吗？'" @ok="remove(scope.row.id)"/>
              </template>
            </el-table-column>
        </el-table>
        <Pager :pager="pager" @change="search"/>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
          <el-form ref="roleForm" :model="role" :rules="rules" label-width="80px">
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :span="12"
                  label="名称"
                  prop="name"
                >
                  <el-input v-model="role.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :span="12"
                  label="编码"
                  prop="code"
                >
                  <el-input v-if="role.id" v-model="role.code" disabled></el-input>
                  <el-input v-else v-model="role.code"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="描述">
                  <el-input v-model="role.description" type="textarea" :rows="3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="createOrUpdate">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog title="权限配置" :visible.sync="powerDialogVisible" width="30%">
          <el-tree
            :data="menuTree"
            show-checkbox
            ref="tree"
            node-key="id"
            :default-expand-all="true"
            :default-checked-keys="checkedMenuIds"
            :props="{children:'children',label:'name'}"
            >
          </el-tree>
          <span slot="footer" class="dialog-footer">
            <el-button @click="powerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="updateRoleMenu()">保 存</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import RoleService from "@/api/sys/role";
import MenuService from "@/api/sys/menu";
import ConfirmButton from '@/components/common/ConfirmButton';
import Pager from '@/components/common/Pager';
import Hex from '@/utils/hex';
import ArrayUtil from "@/utils/array-util";

export default {
    components:{ConfirmButton,Pager},
    data(){
        return{
            pager:{
                rows:[],
                total:0,
                pageSize: 10,
                pageNumber: 1
            },
            params:{
                keyword: ''
            },
            dialogVisible: false,
            powerDialogVisible: false,
            dialogTitle: '',
            role:{},
            
            menuTree:[],
            checkedMenuIds:[],
            currentRole:{},

            rules:{
              name:[
                {
                  required: true,
                  message: '名称不能为空',
                  trigger: 'blur',
                },{
                  validator: (rule, value, callback) => { 
                    RoleService.exists({condition: Hex.encode(`id!=${this.role.id} && name=${value}`)})
                    .then((exists)=>{
                      if(exists){
                        callback("名称重复");
                      }else{
                        callback();
                      }
                    })
                  },
                  trigger: 'blur'
                }
              ],
              code:[
                {
                  required: true,
                  message: '编码不能为空',
                  trigger: 'blur',
                },{
                  validator: (rule, value, callback) => { 
                    RoleService.exists({condition: Hex.encode(`id!=${this.role.id} && code=${value}`)})
                    .then((exists)=>{
                      if(exists){
                        callback("编码重复");
                      }else{
                        callback();
                      }
                    })
                  },
                  trigger: 'blur'
                }
              ],
            },
        }
    },
    created: function () {
      this.search(1);
    },
    methods:{
        showAddDialog(){
          this.dialogTitle = "新增角色";
          this.dialogVisible = true;
          this.role = {
            id: null,
            name: '',
            code: '',
            description: ''
          };
        },
        showUpdateDialog(role){
          this.dialogTitle = "修改角色";
          this.dialogVisible = true;
          this.role = {...role};
        },
        showPowerDialog(role){
          this.currentRole = {...role};
          MenuService.list().then((menus) => {
            const menuTree =
              ArrayUtil.toTree(
                menus,
                { pId: "pid", nodes: "children"},
                null,
                "children"
              ) || [];
            RoleService.listRoleMenu(role.id).then((menuIds)=>{
              this.menuTree = menuTree;
              this.checkedMenuIds = menuIds;
              this.powerDialogVisible = true;
            });
          });
        },
        createOrUpdate(){
          this.$refs.roleForm.validate((valid) =>{
            if(valid){
              if (!this.role.id) {
                RoleService.create(this.role).then(
                  (r) => {
                    this.$message.success("新增成功");
                    this.dialogVisible = false;
                    this.search();
                  },
                  (err) => {}
                );
              } else {
                RoleService.update(this.role).then(
                  (r) => {
                    this.$message.success("修改成功");
                    this.dialogVisible = false;
                    this.search();
                  },
                  (err) => {}
                );
              }
            }
          });
        },
        remove(id){
          RoleService.remove(id).then(
              (r) => {
                this.$message.success("删除成功");
                this.search();
              },
              (err) => {}
          );
        },
        updateRoleMenu(){
          let menuIds = this.$refs.tree.getCheckedKeys();
          RoleService.updateRoleMenu(this.currentRole.id,menuIds).then((data)=>{
            this.$message.success("保存成功");
            this.powerDialogVisible = false;
          },(res)=>{
            this.$message.error("保存失败");
          });
        },
        search: function (pageNumber,pageSize) {
            if(!pageNumber) pageNumber = this.pager.pageNumber;
            if(!pageSize) pageSize = this.pager.pageSize;
            RoleService.pager({
                pageSize: pageSize,
                pageNumber: pageNumber,
                condition: Hex.encode(`name=%${this.params.keyword}% || code=%${this.params.keyword}% || description=%${this.params.keyword}%`)
            }).then((pager) => {
                this.pager = pager;
            });
        },
    }
}
</script>